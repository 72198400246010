import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout includePreFooter>
    <div className='flex flex-col bg-white'>
      <div className='container px-8 mx-auto'>

        <div className='mx-16'>

          {/* hero */}
          <main className='flex flex-wrap items-center py-48 my-12 text-center md:text-left lg:px-24'>
            <div className='w-full whitespace-pre-line md:w-full'>
              <h1 className='mb-6 text-5xl leading-tight text-gray-800'>
                <span className='font-bold'>Page not found!</span>
              </h1>
            </div>
          </main>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
